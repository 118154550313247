<!--
 * @Page: 缴费记录
 * @Author: 张阳帅
 * @Date: 2024-10-11 11:08:52
 * @LastEditTime: 2024-11-14 17:27:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\registerPayList.vue
-->
<template>
  <div class="register_list_page">
    <van-sticky :offset-top="50">
      <div class="page_top">
        <div class="one_item">
          <div class="item_left">
            就诊人
          </div>
          <div class="item_right">
            <div class="item_name">
              {{ patientDict.xm }} {{ patientDict.xbmc }} {{ patientDict.age }}岁
            </div>
            <div class="item_des">
              {{ patientDict.patientCardList[0].jzklxmc }} {{ patientDict.patientCardList[0].jzkh }}
            </div>
          </div>
        </div>
        <van-tabs type="card" color="#666666" @click="seleteTabsAction">
          <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
        </van-tabs>
        <div class="top_time">
          <div class="start_time">
            {{ startTime }}
          </div>
          <div class="time_line">
            -
          </div>
          <div class="end_time">
            {{ endTime }}
          </div>
        </div>
      </div>
    </van-sticky>
    <page-refresh-load :data-source="dataSource" :total="total" :refreshing="refreshing" :loading="loading" @refreshing="onRefresh" @load-more="onLoad">
      <div v-for="(item,index) in dataSource" :key="index" class="body_item">
        <div class="item_cell" @click="getDetailData(item)">
          <div class="cell_one">
            <div class="one_name">
              {{ item.ddlxmc }}收费 <span style="color: #1e8dff;">{{ item.zfztmc }}</span>
            </div>
            <div class="one_pay">
              付款日期: {{ item.fksj }}
            </div>
          </div>
          <div class="cell_two">
            <div class="one_name">
              {{ item.zjeY }}元
            </div>
          </div>
          <div class="cell_three">
            <div class="three_see">
              查看
            </div>
          </div>
        </div>
      </div>
    </page-refresh-load>
    <van-calendar v-model="dateRangeShow" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
    <van-popup v-model="itemShow" closeable lock-scroll position="bottom" :style="{ height: '60%' }">  
      <div class="popup_page">
        <div class="popup_title">
          项目明细
        </div>
        <div v-if="seleteItem.ddlx=='1'" class="popup_body">
          <div v-for="(item, index) in seleteItem.detailList" :key="index" class="popup_body_item">
            <div class="item_list">
              <div class="list_namTitie">
                {{ item.dxmc }}
              </div>
              <div class="list_price">
                ¥{{ item.hjje }} 
              </div>
            </div>
            <div v-for="(feeItem, feeIndex) in item.setDetailList" :key="feeIndex" class="item_list">
              <div class="list_name">
                {{ feeItem.xmmc }}
              </div>
              <div class="list_price">
                ¥{{ feeItem.dj }}  x{{ feeItem.sl }}
              </div>
            </div>
            <div v-if="item.xmlx=='3'" class="item_list">
              <div class="list_num">
                共计:{{ item.setDetailList[0].cfts }}贴
              </div>
            </div>
            <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 5px' }" />
          </div>
        </div>
        <div v-else-if="seleteItem.ddlx=='3'" class="popup_body">
          <div class="popup_body_item">
            <div class="item_list">
              <div class="list_price">
                {{ seleteItem.appointOrder.ksmc }}-{{ seleteItem.appointOrder.ysxm }}
              </div>
              <div class="list_price">
                ¥{{ seleteItem.zjeY }}
              </div>
            </div>
            <div class="item_list">
              <div class="list_price">
                支付状态：<span style="color: #1e8dff;">{{ seleteItem.zfztmc }}</span>
              </div>
            </div>
            <div class="item_list">
              <div class="list_price">
                订单号：{{ seleteItem.appointOrder.ddh }}
              </div>
            </div>
            <div class="item_list">
              <div class="list_price">
                付款时间：{{ seleteItem.fksj }}
              </div>
            </div>
            <div v-if="seleteItem.tfsj" class="item_list">
              <div class="list_price">
                退款时间：{{ seleteItem.tfsj }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="seleteItem.ddlx=='4'" class="popup_body">
          <div class="popup_body_item">
            <div class="item_list">
              <div class="list_price">
                {{ seleteItem.depositInfo.zyks }}-{{ seleteItem.depositInfo.zybq }}
              </div>
              <div class="list_price">
                ¥{{ seleteItem.zjeY }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
  <script>
  import { gjcModel } from '@/api/gjcApi'
  import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'
  
  export default {
      name:'CostList',
      mixins: [pageRefreshLoad],
      data (){
        return{
          patientDict:{},//就诊人字典
          tabs:[{
            name:'近一个月',
            type:'1',
            id:''
          },{
            name:'近三个月',
            type:'2',
            id:''
          },{
            name:'自定义',
            type:'3',
            id:''
          }],
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(),
          dateRangeShow:false,//日期范围选择器
          dataSource:[],
          refreshing:false,
          loading:false,
          startTime:this.getTimeDay(30),//开始时间
          endTime:this.getTimeDay(0),//结束时间
          page:1,
          total:0,
          itemShow:false,
          seleteItem:{}
        }
      },
      created(){
        this.patientDict = JSON.parse(this.$store.getters.patient)
      },
      mounted() {
        this.onRefresh()
      },
      methods:{
        onRefresh() {
          this.page = 1
          this.dataSource = []
          this.total = 0
          this.getList()
        },
        //费用明细
        async getList(){
          const params = {
            page: this.page,
            pageSize: 10,
            startTime:this.startTime,
            //结束时间改为当日最后时分
            endTime:this.endTime + ' 23:59:59',
            hospCode: '', //医院代码
            ddlx: this.$route.query.type, //订单类型 1门诊 2体检 3挂号 4预交金
            patId:this.patientDict.patientId,
            patientCardId: this.patientDict.patientCardList[0].patientCardId
          }
          const res = await gjcModel.getOrderInfoOnListData(params)
          //console.log('费用明细', res)
          this.dataSource.push(...(res.data || []))
          this.total = res.count || 0
        },
        //获取订单详情列表
        getDetailData(item){
          gjcModel.getOrderInfoDetailData({id:item.id}).then(res=>{
            this.seleteItem = {}
            this.itemShow = true
            this.seleteItem = res.data
          })
        },
        dateSubstring(dateStr){
          let time = dateStr
          if(dateStr){
            time = time.substring(0,10)
          }
          return time
        },
        //切换类型
        seleteTabsAction(name){
          this.dateRangeShow = false
          this.dataList = []
          if(name==0){
            this.startTime = this.getTimeDay(30)
            this.onRefresh()
          }else if(name==1){
            this.startTime = this.getTimeDay(90)
            this.onRefresh()
          }else if(name==2){
            this.dateRangeShow = true
          }
          console.log(name, this.dateRangeShow)
        },
        onConfirm(date) {
          const [start, end] = date
          this.startTime = this.formatDate(start)
          this.endTime = this.formatDate(end)
          this.dateRangeShow = false
          this.onRefresh()
        },
        formatDate(date) {
          return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        },
        // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
        getTimeDay(day) {
          var today = new Date()
          var targetday_milliseconds = today.getTime() - 1000 * 60 * 60 * 24 * day
          today.setTime(targetday_milliseconds) //注意，这行是关键代码
          var tYear = today.getFullYear()
          var tMonth = today.getMonth()
          var tDate = today.getDate()
          tMonth = this.doHandleMonth(tMonth + 1)
          tDate = this.doHandleMonth(tDate)
          return tYear + "-" + tMonth + "-" + tDate
        },
        doHandleMonth(month) {
          var m = month
          if (month.toString().length == 1) {
            m = "0" + month
          }
          return m
        }
      }
  }
  </script>
  <style scoped lang='less'>
      .register_list_page{
          background-color: #f5f5f5;
          height: 100vh;
      }
      .page_top{
          border-bottom: 1px solid #f5f5f5;
          background-color: white;
          .one_item{
              display: flex;
              justify-content: space-between;
              justify-items: center;
              padding: 20px 24px;
              border-bottom: 1px solid #f5f5f5;
              background-color: #1e80ff;
  
              .item_left{
                  font-size: 32px;
                  color: white;;
                  line-height: 70px;
              }
              .item_right{
                  text-align: right;
                  font-size: 30px;
                  color: white;
                  .item_des{
                      font-size: 24px;
                  }
              }
          }
          .top_time{
          display: flex;
          justify-content: space-between;
          justify-items: center;
          .start_time{
            margin: 10px 10px 10px 30px;
            padding: 10px;
            text-align: center;
            width: 40%;
            border: 1px solid #ccc;
            font-size: 30px;
            color: #333;
          }
          .time_line{
            margin: 20px 0px;
            font-size: 30px;
            color: #333;
          }
          .end_time{
            margin: 10px 30px 10px 10px;
            padding: 10px;
            text-align: center;
            width: 40%;
            border: 1px solid #ccc;
            font-size: 30px;
            color: #333;
          }
        }
      }
      .body_item{
          display: flex;
          justify-content: space-between;
          background-color: white;
          padding: 24px;
          margin-bottom: 20px;
          .item_cell{
              display: flex;
              // justify-content: space-between;
              justify-content: center;
              justify-items: center;
              font-size: 30px;
              line-height: 50px;
              color: #333;
              .cell_one{
                  width: 380px;
                  .one_pay{
                      font-size: 26px;
                      line-height: 50px;
                      color: #999;
                  }
              }
              .cell_two{
                  display: flex;
                  justify-content: right;
                  margin-top: 25px;
                  width: 140px;
              }
              .cell_three{
                  width: 120px;
                  display: flex;
                  justify-content: right;
                  .three_see{
                      margin-left: 3px;
                      margin-top: 15px;
                      padding: 10px 25px 10px 25px;
                      height: 40px;
                      line-height: 40px;
                      border: 2px solid #666;
                      border-radius: 40px;
                      color:#666;
                  }
              }
          }
      }
      .popup_page{
      position: relative;
      padding: 0 30px;
    }
    .popup_title{
      margin: 10px;
      font-size: 36px;
      width: 100%;
      text-align: center;
      color: #333;
      line-height: 60px;
      font-weight: bold;
    }
    .popup_body{
      padding: 20px 20px;
      height: 50vh;
      overflow: scroll;
      background-color: #f5f5f5;
      border-radius: 12px;
      .popup_body_item{
        display: flex;
        flex-direction: column;
        font-size: 30px;
        color: #333;
        .item_title{
          font-size: 30px;
        }
        .item_list{
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          font-size: 28px;
          color: #666;
          .list_name{
            width: 65%;
          }
          .list_namTitie{
            width: 65%;
            font-size: 28px;
            font-weight: bolder;
          }
          .list_price{
            font-size: 28px;
            color: #666;
          }
          .list_num{
            width: 100%;
            text-align: right;
            color: #333;
          }
        }
      }
    }
  </style>
  